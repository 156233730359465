import React, { useState } from 'react';
import './RankBots.css'; // Ensure you have the CSS styles imported
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RankBots = ({userData, mainRpcConnection}) => {
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({
        mint: '',
        rpcUrl: '',
        timeToRun: '',
        newMakers: 1000, // Default value for slider
    });

    const toggleForm = () => {
        setShowForm(!showForm);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSliderChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            newMakers: e.target.value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted', formData);
        // Add your form submission logic here
    };

  /*  return (
        <div className="rank-bots-container">
            <button className="add-button" onClick={toggleForm}>
                +
            </button>

            {showForm && (
                <form className="rank-bots-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="mint">Mint:</label>
                        <input
                            type="text"
                            id="mint"
                            name="mint"
                            value={formData.mint}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="rpcUrl">RPC URL:</label>
                        <input
                            type="text"
                            id="rpcUrl"
                            name="rpcUrl"
                            value={formData.rpcUrl}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="timeToRun">Time to Run:</label>
                        <input
                            type="text"
                            id="timeToRun"
                            name="timeToRun"
                            value={formData.timeToRun}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="newMakers">New Makers: {formData.newMakers}</label>
                        <input
                            type="range"
                            id="newMakers"
                            name="newMakers"
                            min="1000"
                            max="20000"
                            step="1000"
                            value={formData.newMakers}
                            onChange={handleSliderChange}
                        />  
                    </div>

                    <button type="submit" className="create-button">Create</button>
                </form>
            )}
        </div>
    );*/
    return (

        <div style={{textAlign: 'center'}}>
              <i className="fas fa-lock" ></i>
              <h3>Coming soon...</h3> 
        </div>
    );
};

export default RankBots;
