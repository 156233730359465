import { Connection, Keypair, LAMPORTS_PER_SOL } from "@solana/web3.js";
import _default from "bs58";
export async function getBalanceInFixedSol(connection, privateKeys){
    let publicKeys = []
    try{
    for (let i = 0; i < privateKeys.length; i++) {
        const key = privateKeys[i];
        publicKeys.push(Keypair.fromSecretKey(_default.decode(key)).publicKey);
    }
    let walletsInfo = await connection.getMultipleAccountsInfo(publicKeys, {commitment: "processed"});
    let totalBalance = 0;
    for (let index = 0; index < walletsInfo.length; index++) {
        const currBalance = walletsInfo[index].lamports;
        if(currBalance>(0.0011)*LAMPORTS_PER_SOL){
            totalBalance+=parseInt(currBalance);
        }
    }
    if(totalBalance/LAMPORTS_PER_SOL<=0.001){return 0}
    return ((totalBalance/LAMPORTS_PER_SOL)).toFixed(4);
}catch{return "Error"}
}

export async function getBalanceLamportsForSingleAccount(connection, privateKey){
    try{
    let publicKey = Keypair.fromSecretKey(_default.decode(privateKey)).publicKey;
    let balance = await connection.getBalance(publicKey, {commitment: "confirmed"});
    
    return balance
}catch{return "Error"}
}