import React, { useEffect, useState } from 'react';
import './BumpBots.css'
import { deleteBumpBotInfo, getUserBumpBots, startBumpBot, stopBumpBot } from '../../helpers/apiMethods';
import downArrowIcon from '../../assets/downArrowWhite.png'
import { MAX_BUMP_BOTS, PRICE_PER_BOT, TASK_STATUS } from '../../helpers/constants';
import { Connection, Keypair, LAMPORTS_PER_SOL } from '@solana/web3.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import * as bs58 from 'bs58' 
import BumpBotItem from './BumpBotItem';
import { sleep } from '../../helpers/utils';
import { getBalanceInFixedSol } from '../../helpers/solUtils';

const BumpBots = ({userData}) => {
  //FIX DELAY BETWEEN TXS IS A STRING
    // Initial bot data with name, wallets, total SOL, taskId, and running state
 /*   const [bots, setBots] = useState([
        { id: 1, name: 'Bot 1', wallets: 2, totalSOL: 1.5, taskId: 'task123', running: true },
        { id: 2, name: 'Bot 2', wallets: 4, totalSOL: 2.0, taskId: 'task456', running: false },
    ]);*/
    const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bots, setBots] = useState([]);
  const [isCreateBumpErrorModalVisible, setIsCreateBumpErrorModalVisible] = useState(false);
  const [isCreateBumpSuccessModalVisible, setIsCreateBumpSuccessModalVisible] = useState(false);
  const [isKillBumpBotSuccessModalVisible, setIsKillBumpBotSuccessModalVisible] = useState(false);
  const [isKillBumpBotErrorModalVisible, setIsKillBumpBotErrorModalVisible] = useState(false);
  const [isMaxBumpBotReachedModalVisible, setIsMaxBumpBotReachedModalVisible] = useState(false);
  const [botInfo, setBotInfo]= useState(undefined);
  const [isBotInfoModalVisible, setIsBotInfoModalVisible] = useState(false);
  const [isConfirmDeleteBumpBotModalVisible, setIsConfirmDeleteBumpBotModalVisible] = useState(false);
  const [botInfoToDelete, setBotInfoToDelete] = useState(-1);
  const [isInsufficientBalanceVisible, setIsInsufficientBalanceVisible]= useState(false);
 
  const [mainRpcConnection, setMainRpcConnection] = useState(new Connection(userData.MAIN_RPC_URL, 'confirmed'));
  
  
  /*const updateBumpBotsSolBalance = async (_bots) => {
    // Map through the bots and handle async operations for each bot
    let newBumpBotList = await Promise.all(
      _bots.map(async (bot) => {
        let wallets = bot.wallets;
        let finalBalance = 0;
  
        for (let index = 0; index < wallets.length; index++) {
          const wallet = wallets[index];
          try {
            // Fetch the balance for each wallet
            const newBalance = await mainRpcConnection.getBalance(Keypair.fromSecretKey(bs58.default.decode(wallet)).publicKey);
            finalBalance += parseFloat((newBalance / LAMPORTS_PER_SOL).toFixed(4));
          } catch (error) {
            console.log("Failed to fetch bump bot balance", error);
          }
        }
  
        // Return updated bot with the new solBalance
        return {
          ...bot,
          solBalance: finalBalance
        };
      })
    );
    if(newBumpBotList.length>0){setBots(newBumpBotList);}
    // Now you can safely update the state with the new bot list

    //setBots(newBumpBotList);
  };
  
  useEffect(() => {
    const intervalId = setInterval(async () => {
      await updateBumpBotsSolBalance(bots);
    }, 5000); // Update every 5 seconds
  
    return () => clearInterval(intervalId); // Clean up interval on unmount
  }, []);*/
  const showDeleteBumpBotDialog = (__botInfo) => {
    setBotInfoToDelete(__botInfo)
      setIsConfirmDeleteBumpBotModalVisible(true);
  }
  const toggleBotInfoModal = (_botInfo) => {
      setIsBotInfoModalVisible(!isBotInfoModalVisible);
      setBotInfo(_botInfo);
  };

  const updateTimesForBots = (_bots) => {
    // Create a new array to ensure immutability
    let newBotsList = _bots.map((bot) => {
      if (bot.timeLeft <= 0 || (Date.now() > ((parseInt(bot.startDate) + bot.secondsToRun * 1000))) || bot.status == TASK_STATUS.FINISHED) {
        return {
          ...bot,
          timeLeft: -1,  // Time is zero since it's finished
          progressPct: 100,  // 100% progress
          status: TASK_STATUS.FINISHED,  // Set status to FINISHED (or whatever value you use for completed bots)
        };
      }
      // Only update bots that are running
      if (bot.status == TASK_STATUS.RUNNING) {
        const timeLeft = parseInt((((parseInt(bot.startDate) + bot.secondsToRun * 1000) - Date.now()) / 1000).toFixed(0));
        const progressPct = ((((bot.secondsToRun * 1000 - timeLeft * 1000) / (bot.secondsToRun * 1000)) * 100));
        
        return {
          ...bot,
          timeLeft: timeLeft,
          progressPct: progressPct
        };
      }
      
      return bot;
    });
    
    setBots(newBotsList);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateTimesForBots(bots);
    }, 1000); // Update every second
  
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [bots]);



    //Bump Bot create form
    const [showForm, setShowForm] = useState(false); // To toggle form visibility
  const [botData, setBotData] = useState({
    name: 'New Bot',
    minSol: '0.01',
    maxSol: '0.02',
    rpcUrl: `${userData.MAIN_RPC_URL}`,
    walletNumber: 1,
    mint: '',
    minutesToRun: '30',
    delayBetweenTxs : '10',
  });
  function clearBotData (){
    setBotData({
        name: 'New Bot',
        minSol: '0.01',
        maxSol: '0.02',
        rpcUrl: `${userData.MAIN_RPC_URL}`,
        walletNumber: 1,
        mint: '',
        minutesToRun: '5',
        delayBetweenTxs : '10',
      });
  }
  //fetch the bumpBot data (in the future fetch more lol);
  useEffect(() => {

    getUserBumpBots(userData.API_KEY).then(data => 
      {
        let newBots = [];
        for (let index = 0; index < data.length; index++) {
          const bumpBot = data[index];
          let newBotData = bumpBot;
          newBotData.minutesToRun = Math.round(parseFloat(newBotData.secondsToRun)/60);
          newBotData.minSol = newBotData.minSolAmount;
          newBotData.maxSol = newBotData.maxSolAmount;
          newBotData.walletNumber = newBotData.wallets.length;
          let taskId = newBotData.taskId;
          //let checkBotIndex = bots.findIndex(bot => bot.taskId==newBotData.taskId);
          //console.log(checkBotIndex)
          newBots.push(newBotData);
        }
        setBots(newBots);
      });
   }, []) 
  function getTotalBumpNeededSol(){
    return ((parseFloat(botData.maxSol)*1.2)*parseFloat(botData.walletNumber)+ (parseFloat(addBumpBotCalculatedCost))).toFixed(3);
  }
  function calculateBumpCost(_delayBetweenTxs, _minSolAmount, _maxSolAmount, _minutesToRun, _walletNumber){
    let delayBetweenTxs = parseFloat(_delayBetweenTxs);
    let minSolAmount    = parseFloat(_minSolAmount);
    let maxSolAmount    = parseFloat(_maxSolAmount);
    let minutesToRun    = parseFloat(_minutesToRun);
    let walletNumber    = parseFloat(_walletNumber);
    let txCost = ((maxSolAmount+minSolAmount)/2)*0.02 //fees per tx (buy 1%, sell 1% = 2% (0.02))
    let txNumber = ((minutesToRun*60) / delayBetweenTxs) * walletNumber //number of transactions (secondsToRun / timePerTx) * walletNumber
    return ((txCost*txNumber)+(0.001*walletNumber)).toFixed(4);
}
  const [addBumpBotCalculatedCost, setAddBumpBotCalculatedCost] = useState(0);
  // Handle form field changes for create bump bot form
  const handleChange = (e) => {
    const { name, value } = e.target;
    //if(name==='minutesToRun' ){if (value>60){alert("Maximum minutes to run per bot is 60")}; return;}
    setBotData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setAddBumpBotCalculatedCost(
        calculateBumpCost(
          name === 'delayBetweenTxs' ? value : botData.delayBetweenTxs,
          name === 'minSol' ? value : botData.minSol,
          name === 'maxSol' ? value : botData.maxSol,
          name === 'minutesToRun' ? value : botData.minutesToRun,
          name === 'walletNumber' ? value : botData.walletNumber
        )
      );  };

  const handleKillBumpBot = async (botInfo) => {
    let botIndex = bots.findIndex(item => item.taskId === botInfo.taskId)
    if(botIndex!=-1){
      setIsLoading(true);
      let isSuccess = await stopBumpBot(userData.API_KEY, botInfo, userData);
      if(isSuccess){
        setIsKillBumpBotSuccessModalVisible(true)
        const updatedBots = [...bots]; // Create a shallow copy of the bots array
        updatedBots[botIndex] = { 
          ...updatedBots[botIndex], // Copy the existing bot's properties
          status: TASK_STATUS.FINISHED, 
          minutesToRun: 0, 
          progressPct: 100 
        };
    
        // Update the state with the modified array
        setBots(updatedBots);
      }else {setIsKillBumpBotErrorModalVisible(true)}
      setIsLoading(false);
    }else {setIsKillBumpBotErrorModalVisible(true)}
    
  }
  const handleDeleteBumpBot = async()=> {
    let botIndex = bots.findIndex(item => item.taskId === botInfoToDelete.taskId)

    if(botIndex!=-1){
    setIsLoading(true);
    let isSuccess = await deleteBumpBotInfo(userData.API_KEY, botInfoToDelete, userData);
    await sleep(1000);
    if(isSuccess){
      const updatedBots = [...bots]; // Create a shallow copy of the bots array
        updatedBots.splice(botIndex, 1);
         setBots(updatedBots);
      setIsConfirmDeleteBumpBotModalVisible(false);
    }else {setIsConfirmDeleteBumpBotModalVisible(false);setIsKillBumpBotErrorModalVisible(true); }
    setIsLoading(false);
  }else {console.log(bots);setIsConfirmDeleteBumpBotModalVisible(false);setIsKillBumpBotErrorModalVisible(true)}
  }
  // Handle form submission (currently just logs the data)
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsModalVisible(true);
    setShowForm(false); // Hide the form after submission
  };
  const handleYesClick = async () => {
    setIsModalVisible(false); // Hide modal
    setIsLoading(true); // Show loading spinner

    try {
      // Await the startBumpBot function with botData
      //handle starting bot
      console.log(botData)
      let balance = await getBalanceInFixedSol(new Connection(userData.MAIN_RPC_URL, 'confirmed'), [userData.FUND_WALLET]);
      if(parseFloat(balance)-0.02 < (parseFloat(getTotalBumpNeededSol())+PRICE_PER_BOT)){setIsInsufficientBalanceVisible(true);return;}
      const newBotInfo =  await startBumpBot(botData, userData, (parseFloat(getTotalBumpNeededSol())/botData.walletNumber).toFixed(3), botData.rpcUrl, bots);
      if (newBotInfo == "MAX_BUMP_BOTS_REACHED"){setIsMaxBumpBotReachedModalVisible(true)}
      else if(newBotInfo == false){      setIsCreateBumpErrorModalVisible(true);      } //handle error
      else{         
         let taskId = newBotInfo.taskId; botData.wallets = newBotInfo.generatedWallets; botData.status = TASK_STATUS.RUNNING; botData.startDate = Date.now().toString();
         botData.secondsToRun = parseInt(botData.minutesToRun)*60;
         
        setBots([...bots, { ...botData, taskId }]); setIsCreateBumpSuccessModalVisible(true)  
      }

      // Add the new bot with the returned taskId to the bots list

      // Hide the loading spinner after the bot is successfully added
    } catch (error) {
      console.error("Error starting bot:", error);
      setIsCreateBumpErrorModalVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle the "No" button click in the modal
  const handleNoClick = () => {
    setIsConfirmDeleteBumpBotModalVisible(false)
    setIsModalVisible(false); // Hide the modal
  };
  const handleOkClick = () => {
    setIsCreateBumpErrorModalVisible(false); // Hide the modal
    setIsCreateBumpSuccessModalVisible(false);
    setIsKillBumpBotErrorModalVisible(false);
    setIsKillBumpBotSuccessModalVisible(false);
    setIsMaxBumpBotReachedModalVisible(false);
    setIsInsufficientBalanceVisible(false);
  };
    // Handle name editing
    const handleNameChange = (id, newName) => {
        const updatedBots = bots.map(bot => {
            if (bot.id === id) {
                return { ...bot, name: newName };
            }
            return bot;
        });
        setBots(updatedBots);
    };

    // Toggle bot between running and stopped
    const toggleBot = (id) => {
        const updatedBots = bots.map(bot => {
            if (bot.id === id) {
                return { ...bot, running: !bot.running };
            }
            return bot;
        });
        setBots(updatedBots);
    };

    return (
        
        <div className="bump-bots-container">
                      
            {(bots.length <= 0) &&(
              <h2 className='bump-bots-title'>No bump bots active</h2>
            )}
            {showForm && (
        <div className="bot-form-container">
            <button className="close-button" onClick={() => setShowForm(false)}>
              X
            </button>
          <h3>Create a New Bump Bot</h3>
          <form onSubmit={handleSubmit} className="bot-form">
            <label>
              Name:
              <input
                type="text"
                name="name"
                placeholder='Enter name for bot'
                value={botData.name}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              <input
              placeholder='Enter mint'
              
                type="text"
                name="mint"
                value={botData.mint}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              RPC URL:
              <input
                type="text"
                name="rpcUrl"
                value={botData.rpcUrl}
                onChange={handleChange}
                required
              />
            </label>
            
            <div className="form-row">
            <div className='form-group'>
            <label>
              Min. SOL per bump:
              <input
                type="number"
                name="minSol"
                value={botData.minSol}
                onChange={handleChange}
                required
              />
            </label>
            </div>
            <div className='form-group'>

            <label>
              Max. SOL per bump:
              <input
                type="number"
                name="maxSol"
                value={botData.maxSol}
                onChange={handleChange}
                required
              />
            </label>
            </div>
            </div>
           
            <label>
              Wallet Number:
              <select
                name="walletNumber"
                value={botData.walletNumber}
                onChange={handleChange}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
            </label>
            
            <div className="form-row">
                <div className='form-group'>
            <label>
              Minutes to run:
              <input
                type="number"
                name="minutesToRun"
                value={botData.minutesToRun}
                onChange={handleChange}
                max="60" // Setting the maximum value
                required
              />
            </label>
            </div>
            <div className='form-group'>

            <label>
              Secs. between bumps:
              <input
                type="number"
                name="delayBetweenTxs"
                value={botData.delayBetweenTxs}
                onChange={handleChange}
                required
              />
            </label>
            </div>
            </div>
            <h5 className='bump-bot-calculated-cost' style={{marginTop: '-15px'}}>Calculated costs: </h5>
            <h5 className='bump-bot-calculated-cost'>- Pump.fun fees (max.): {(parseFloat(addBumpBotCalculatedCost)).toFixed(4)} SOL </h5>
            <h5 className='bump-bot-calculated-cost'>- Creation cost (fixed): {PRICE_PER_BOT} SOL </h5>
            <h5 className='bump-bot-calculated-cost'>- Total deposit: {(parseFloat(getTotalBumpNeededSol())+PRICE_PER_BOT).toFixed(4)} SOL </h5>
            <div style={{ display: 'flex', alignItems: 'center', color: '#28a745' }}>
      <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: '10px', fontSize: '16px' }} />
      <span style={{ fontSize: '12px' }}>This action is eligible for Airdrop rewards</span>
    </div>
            <button type="submit">Create Bot</button>
            
          </form>
        </div>
      )}
       {isModalVisible && (
        <div className="modal">
          <div className="modal-content">
            <p>A total of {(parseFloat(getTotalBumpNeededSol())+PRICE_PER_BOT).toFixed(4)} SOL will be transferred to the bot wallets  (Only {(parseFloat(addBumpBotCalculatedCost)+PRICE_PER_BOT).toFixed(4)} SOL will be used, leftover will be withdrawn automatically upon stopping the bot), proceed?</p>
            <div className="modal-buttons">
              <button className='yes-no-button' onClick={handleYesClick}>Yes</button>
              <button className='yes-no-button' onClick={handleNoClick}>No</button>
            </div>
          </div>
        </div>
      )}
      {isCreateBumpErrorModalVisible && (
        <div className="modal-error">
          <div className="modal-content">
            <p>There was an error while creating the bot, please make sure the data entered is valid and you have enough SOL in your wallet to fund it.</p>
            <div className="modal-buttons">
              <button onClick={handleOkClick}>OK</button>
            </div>
          </div>
        </div>
      )}
      {isMaxBumpBotReachedModalVisible && (
        <div className="modal-error">
          <div className="modal-content">
            <p>Maximum active Bump Bots limit reached.</p>
            <div className="modal-buttons">
              <button onClick={handleOkClick}>OK</button>
            </div>
          </div>
        </div>
      )}
       {isCreateBumpSuccessModalVisible && (
        <div className="modal-success">
          <div className="modal-content">
            <p>Bot created successfully!</p>
            <div className="modal-buttons">
              <button onClick={handleOkClick}>OK</button>
            </div>
          </div>
        </div>
      )}
      {isKillBumpBotErrorModalVisible && (
        <div className="modal-error">
          <div className="modal-content">
            <p>There was an error while performing this action</p>
            <div className="modal-buttons">
              <button onClick={handleOkClick}>OK</button>
            </div>
          </div>
        </div>
      )}
      {isInsufficientBalanceVisible && (
        <div className="modal-error">
          <div className="modal-content">
            <p>Insufficient balance for action, please top-up using the wallet menu in the top right<br>Please make sure to always have a surplus of 0.02 SOL for each action to avoid transaction failures!</br></p>
            <div className="modal-buttons">
              <button onClick={handleOkClick}>OK</button>
            </div>
          </div>
        </div>
      )}
       {isKillBumpBotSuccessModalVisible && (
        <div className="modal-success">
          <div className="modal-content">
            <p>Task killed successfully, use the balance menu to retrieve the leftover SOL!</p>
            <div className="modal-buttons">
              <button onClick={handleOkClick}>OK</button>
            </div>
          </div>
        </div>
      )}
      {isConfirmDeleteBumpBotModalVisible && (
        <div className="modal">
        <div className="modal-content">
          <p>Are you sure you want to delete this bot?</p>
          <div className="modal-buttons">
            <button className='yes-no-button' onClick={handleDeleteBumpBot}>Yes</button>
            <button className='yes-no-button' onClick={handleNoClick}>No</button>
          </div>
        </div>
      </div>
      )}
    
    {isBotInfoModalVisible && (
        <>
          {/* Overlay for background blur effect */}
          <div className="modal-overlay" onClick={toggleBotInfoModal}></div>
          
          {/* Modal that slides in from the bottom */}
          <div className="info-modal">
            <button className="close-button" onClick={toggleBotInfoModal}>X</button>
            <h3>Mint</h3>
            <div className='info-field'>
            
            <p><strong></strong> {botInfo.mint}</p>
            </div>
            <h3>RPC URL</h3>

            <div className='info-field'>

            <p><strong></strong> {botInfo.rpcUrl}</p>
            </div>
   
      


          </div>
         
        </>
      )}
      {/* Loading spinner */}
      {isLoading && (
    <div className="loading-container">
        <div className="loading-box">
            <p></p>
            <div className="spinner"></div>
        </div>
    </div>
)}
           <div className="bot-list">
           
           {bots.map((bot, index) => (
            <BumpBotItem 
            bot={bot} 
            index={index} 
            handleKillBumpBot={handleKillBumpBot}
            userData={userData}
            toggleBotInfoModal={toggleBotInfoModal}
            mainRpcConnection={mainRpcConnection}
            showDeleteBumpBotDialog={showDeleteBumpBotDialog}
            ></BumpBotItem>
        ))}

      {bots.length<MAX_BUMP_BOTS&&(<button className="add-bot-button" onClick={() => setShowForm(true)}>
        + 
      </button>)}
    </div>

    </div>
    );
};

export default BumpBots;
