import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { API_ENDPOINT } from './helpers/constants';
import { getUserData, getUserPermissions, sendTransfer } from './helpers/apiMethods';
import QRCode from 'react-qr-code';
import PumpFunTools from './tabs/PumpFunTools/PumpFunTools';
import RaydiumTools from './tabs/RaydiumTools/RaydiumTools';
import Settings from './tabs/Settings';
import { Connection, PublicKey, clusterApiUrl, Keypair, LAMPORTS_PER_SOL } from '@solana/web3.js';
import * as bs58 from 'bs58';
// Font Awesome Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

// Assets
import pumpFunIcon from './assets/pumpFunIcon.png';
import raydiumIcon from './assets/raydiumIcon.png';
import settingsIcon from './assets/settingsIcon.png';
import walletIcon from './assets/walletIcon.png';
import solanaIcon from './assets/solanaIcon.png';
import turboFunIcon from './assets/turboFunIcon.png';

let staticPerms = null;

function App() {
  const [apiKey, setApiKey] = useState('');
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('Pump.fun Tools'); // Active tab state
  const [balance, setBalance] = useState(-1); //initial balance -1 to signal not initiated
  const [isWalletOpen, setIsWalletOpen] = useState(false);
  const [mainWallet, setMainWallet] = useState('');
  const [balanceConnection, setBalanceConnection] = useState(new Connection(clusterApiUrl('mainnet-beta'), 'confirmed'));
  const [isWithdrawPromptOpen, setIsWithdrawPromptOpen] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [withdrawAddress, setWithdrawAddress] = useState('');
  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false);
  const [withdrawingMessage, setWithdrawingMessage] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [userData, setUserData] = useState();
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [receivedAmount, setReceivedAmount] = useState(0);
  const lastNotifiedBalance = useRef(null); // Use useRef to track the last notified balance

  // State for Hamburger Menu
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const setMaxWithdrawAmount = () => {
    setWithdrawAmount((balance - 0.0009).toFixed(4).toString());
  };

  const openWithdrawPrompt = () => {
    setIsWithdrawPromptOpen(true);
  };

  const closeWithdrawPrompt = () => {
    setIsWithdrawPromptOpen(false);
  };

  const handleWithdrawAmountChange = (event) => {
    setWithdrawAmount(event.target.value);
  };

  const handleWithdrawAddressChange = (event) => {
    setWithdrawAddress(event.target.value);
  };

  const closeMessage = () => {
    setShowMessage(false); // Close the message box
  };

  const withdrawFunds = async (mainWallet, addressToWithdrawTo, solAmount) => {
    setIsWithdrawLoading(true);
    try {
      // Simulating the async withdrawal process
      if (solAmount > balance) {
        setIsWithdrawLoading(false);
        setIsSuccess(false);
        setMessage("Withdrawal amount is higher than your current balance");
        setShowMessage(true);
        return;
      }
      try {
        let testKey = new PublicKey(addressToWithdrawTo);
      } catch {
        setIsWithdrawLoading(false);
        setIsSuccess(false);
        setMessage("Withdrawal Address is invalid");
        setShowMessage(true);
        return;
      }
      setWithdrawingMessage(`Withdrawing ${solAmount} SOL to ${addressToWithdrawTo}`);

      let transferResponse = await sendTransfer(apiKey, mainWallet, addressToWithdrawTo, solAmount, balanceConnection.rpcEndpoint); // Simulate a 3-second delay
      console.log(`Withdraw ${solAmount} SOL to ${addressToWithdrawTo} from ${mainWallet}`);
      if (transferResponse !== false) {
        setIsWithdrawLoading(false);
        setIsSuccess(true);
        setMessage("Withdrawal was successful!");
        setShowMessage(true);
      } else {
        setIsWithdrawLoading(false);
        setIsSuccess(false);
        setMessage("Error while simulating transaction...");
        setShowMessage(true);
      }
    } catch (error) {
      setIsSuccess(false);
      setMessage("Withdrawal failed due to internal error, please review your inputs. \nIf this error persists, please contact our support via Telegram or at contact@turbofun.io");
      setShowMessage(true);
      console.error('Withdrawal failed', error);
    } finally {
      setIsWithdrawLoading(false);
    }
  };

  const confirmWithdraw = () => {
    withdrawFunds(mainWallet, withdrawAddress, withdrawAmount);
  };

  useEffect(() => {
    const fetchBalance = async () => {
      // Simulate an API call to fetch balance
      //console.log(mainWallet);
      if (mainWallet !== "" && balanceConnection.rpcEndpoint !== clusterApiUrl('mainnet-beta')) {
        try {
          const newBalance = (await balanceConnection.getBalance(Keypair.fromSecretKey(bs58.default.decode(mainWallet)).publicKey));
          //console.log(newBalance)
          let currentBalanceSOL = (newBalance / LAMPORTS_PER_SOL).toFixed(4);
          let currentBalanceFloat = parseFloat(currentBalanceSOL); //offset to not log fake trx
          let lastBalanceFloat = parseFloat(balance);
          console.log({ currentBalanceFloat, lastBalanceFloat });
          if (currentBalanceFloat > lastBalanceFloat && lastNotifiedBalance.current !== currentBalanceFloat && lastBalanceFloat > -1) {
            setIsNotificationVisible(true);
            setReceivedAmount((currentBalanceFloat - lastBalanceFloat).toFixed(3));
            lastNotifiedBalance.current = currentBalanceFloat;
            // Hide notification after 5 seconds
            setTimeout(() => {
              setIsNotificationVisible(false);
            }, 5000);
          }

          setBalance(currentBalanceSOL);
        } catch {
          setIsSuccess(false);
          setMessage("Your default RPC URL is invalid, without a proper RPC URL, the app will not work. Please change it in settings, if you don't have one, you can get a free one at https://helius.dev (recommended) (Default solana RPC URL will not work)");
          setShowMessage(true);
        }
      }
    };

    fetchBalance(); // Fetch immediately when component mounts
    const interval = setInterval(fetchBalance, 20000); // Every 20 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [mainWallet, balanceConnection, balance, lastNotifiedBalance]);

  const toggleWalletMenu = () => {
    setIsWalletOpen(!isWalletOpen);
  };

  // Function to handle API key submission
  const handleSubmit = async () => {
    setLoading(true);
    const perms = await getUserPermissions(apiKey);
    const userDataInit = await getUserData(apiKey);

    // console.log(mainWallet);
    if (perms === false) {
      setError(true);
      setSubmitted(false);
      setLoading(false);
    } else {
      staticPerms = perms;
      setMainWallet(userDataInit.FUND_WALLET);
      setUserData(userDataInit);
      try {
        setBalanceConnection(new Connection(userDataInit.MAIN_RPC_URL, 'confirmed'));
      } catch {
        setIsSuccess(false);
        setMessage("Your default RPC URL is invalid, without a proper RPC URL, the app will not work. Please change it in settings, if you don't have one, you can get a free one at https://helius.dev (recommended) (Default solana RPC URL will not work)");
        setShowMessage(true);
      }
      setError(false);
      setSubmitted(true);
      setLoading(false);
    }
  };

  // Handle input change
  const handleInputChange = (e) => {
    setApiKey(e.target.value);
  };

  // Function to render the content of the active tab
  const renderTabContent = () => {
    switch (activeTab) {
      case 'Pump.fun Tools':
        return <PumpFunTools userData={userData} mainRpcConnection={balanceConnection} />;
      case 'Raydium Tools':
        return <RaydiumTools userData={userData} mainRpcConnection={balanceConnection} />;
      case 'Settings':
        return <Settings apiKey={apiKey} />;
      default:
        return null;
    }
  };

  // Handle clicking outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div className="App">
      {!submitted && !loading && (
        <div className="api-modal">
          <div className="api-modal-content">
            <h4>Welcome, please enter your API key!</h4>
            <input
              type="password"
              value={apiKey}
              onChange={handleInputChange}
              placeholder="Enter API Key"
            />
            <button onClick={handleSubmit}>Login</button>
            <a href='https://t.me/turbofun_bot'>
              <h6 className='no-key-text'>Don't have a Key?</h6>
            </a>
          </div>
        </div>
      )}

      {loading && (
        <div className="api-modal">
          <div className="api-modal-content">
            <p>Logging in...</p>
          </div>
        </div>
      )}

      {error && !loading && (
        <div className="api-modal">
          <div className="api-modal-content">
            <p>Invalid API Key</p>
            <button onClick={() => setError(false)}>Try again</button>
          </div>
        </div>
      )}

      {submitted && !error && !loading && (
        <div className="app-container">

          {/* Header Section */}
          <a href="http://turbofun.io">
            <img src={turboFunIcon} className='turbo-fun-icon-top-left' alt="TurboFun" />
          </a>

          {/* Tab Navigation */}
          <div className="tab-navigation">
            {/* Hamburger Menu for Mobile */}
            <div className="hamburger-menu" ref={menuRef}>
              <button className="hamburger-icon" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="lg" />
              </button>
              {isMenuOpen && (
                <div className="dropdown-menu">
                  <button
                    className={`dropdown-item ${activeTab === 'Pump.fun Tools' ? 'active' : ''}`}
                    onClick={() => { setActiveTab('Pump.fun Tools'); setIsMenuOpen(false); }}
                  >
                    Pump.fun Tools
                  </button>
                  <button
                    className={`dropdown-item ${activeTab === 'Raydium Tools' ? 'active' : ''}`}
                    onClick={() => { setActiveTab('Raydium Tools'); setIsMenuOpen(false); }}
                  >
                    Raydium Tools
                  </button>
                  <button
                    className={`dropdown-item ${activeTab === 'Settings' ? 'active' : ''}`}
                    onClick={() => { setActiveTab('Settings'); setIsMenuOpen(false); }}
                  >
                    Settings
                  </button>
                </div>
              )}
            </div>

            {/* Tab Buttons for Desktop */}
            <div className="tab-buttons">
              <button
                className={`tab-button ${activeTab === 'Pump.fun Tools' ? 'active' : ''}`}
                onClick={() => setActiveTab('Pump.fun Tools')}
              >
                <img src={pumpFunIcon} alt="Pump.fun" className="pumpfuntab-icon" />
              </button>
              <button
                className={`tab-button ${activeTab === 'Raydium Tools' ? 'active' : ''}`}
                onClick={() => setActiveTab('Raydium Tools')}
              >
                <img src={raydiumIcon} alt="Raydium" className="raydiumtab-icon" />
              </button>
              <button
                className={`tab-button ${activeTab === 'Settings' ? 'active' : ''}`}
                onClick={() => setActiveTab('Settings')}
              >
                <img src={settingsIcon} alt="Settings" className="settingstab-icon" />
              </button>
            </div>
          </div>

          {/* Wallet Section */}
          <div className="wallet-section" style={{ position: 'absolute', top: '10px', right: '20px' }}>
            <button className="wallet-button" onClick={toggleWalletMenu}>
              <img src={walletIcon} alt="Wallet" className="wallet-icon" />
              <span className="wallet-balance">{balance} SOL</span>
            </button>

            {/* Wallet Dropdown Menu */}
            {isWalletOpen && (
              <div className="wallet-modal">
                <div className="wallet-modal-content">
                  <button className="close-button" onClick={toggleWalletMenu}>X</button>
                  <h3>Deposit/Withdraw</h3>
                  <div className="deposit-address">
                    <p>Your deposit address is</p>
                    <p><strong>{Keypair.fromSecretKey(bs58.default.decode(mainWallet)).publicKey.toBase58()}</strong></p>
                    <p><strong>OR</strong></p>
                    <p><strong>If you are using a mobile wallet, just scan this QR code</strong></p>
                  </div>
                  <div className="qr-code">
                    <QRCode
                      className="qr-code"
                      value={Keypair.fromSecretKey(bs58.default.decode(mainWallet)).publicKey.toBase58()}
                      size={128}
                      bgColor="#ffffff"
                      fgColor="#8800ff"
                    />
                  </div>
                  <button className="wallet-action-button" onClick={openWithdrawPrompt}>
                    Withdraw
                  </button>
                </div>
              </div>
            )}

            {/* Notification */}
            {isNotificationVisible && (
              <div className="notification" style={{ display: isNotificationVisible ? 'block' : 'none' }}>
                You have received {receivedAmount} SOL
              </div>
            )}

            {/* Withdraw Prompt */}
            {isWithdrawPromptOpen && (
              <div className="withdraw-prompt-modal">
                <div className="withdraw-prompt-content">
                  <button className="close-button" onClick={closeWithdrawPrompt}>X</button>
                  <h5>Your balance: {balance} SOL</h5>
                  <h4>Enter withdrawal info</h4>

                  <input
                    type="text"
                    value={withdrawAddress}
                    onChange={handleWithdrawAddressChange}
                    placeholder="Withdrawal Address"
                    className="withdraw-input"
                  />
                  <div className="withdraw-amount-input">
                    <input
                      type="text"
                      value={withdrawAmount}
                      onChange={handleWithdrawAmountChange}
                      placeholder="X SOL"
                      className="withdraw-input"
                    />
                    <button className="max-button" onClick={setMaxWithdrawAmount}>MAX</button>
                  </div>
                  {isWithdrawLoading ? (
                    <div className="loading-container">
                      <div className="loading-spinner"></div>
                      <p>{withdrawingMessage}</p>
                    </div>
                  ) : (
                    <button className="confirm-button centered" onClick={confirmWithdraw}>
                      Confirm
                    </button>
                  )}
                </div>
              </div>
            )}

            {/* Message Modal */}
            {showMessage && (
              <div className="message-modal">
                <div className={`message-content ${isSuccess ? 'success' : 'error'}`}>
                  <p>{message}</p>
                  <button className="ok-button" onClick={closeMessage}>OK</button>
                </div>
              </div>
            )}
          </div>

          {/* Tab Content */}
          <div className="tab-content-container">{renderTabContent()}</div>
        </div>
      )}

    </div>
  );
}

export default App;
