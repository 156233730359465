import { Connection, Keypair, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { API_ENDPOINT, MAX_BUMP_BOTS } from "./constants"
import _default from "bs58";
export async function getUserPermissions(apiKey){
    const data = {apiKey : apiKey}
    try {
        const response = await fetch(`${API_ENDPOINT}getUserPermissions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.status==200 || response.status==401) {
            return false;
        }

        const result = await response.json();
        if(response.status==200){return result}
    } catch (error) {
        console.error('Error while making API request: ', error);
        return false;
    }
}   

export async function getUserData(apiKey){
    const data = {apiKey : apiKey}
    try {
        const response = await fetch(`${API_ENDPOINT}getUserData`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.status==200 || response.status==401) {
            return false;
        }

        const result = await response.json();
        if(response.status==200){return result}
    } catch (error) {
        console.error('Error while making API request: ', error);
        return false;
    }
}

export async function setUserData(apiKey, newData){
    const data = {apiKey : apiKey, newData : newData}
    try {
        const response = await fetch(`${API_ENDPOINT}updateUserData`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.status==200 || response.status==401) {
            return false;
        }

        const result = await response.json();
        if(response.status==200){return result}
    } catch (error) {
        console.error('Error while making API request: ', error);
        return false;
    }
}
export async function sendTransfers(apiKey, rpcUrl, transfers, jitoPayerPrivkey){
    const data = {
        apiKey : apiKey,
        transfers : transfers,
        rpcUrl : rpcUrl, 
        jitoTipPayerPrivateKey : jitoPayerPrivkey, 
        simulateTransactions: true, 
        jitoTipLamports: 1000

    }
    try {
        const response = await fetch(`${API_ENDPOINT}sendTransfer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.status==200 || response.status==401 || response.status==400) {
            return false;
        }

        const result = await response.json();
        if(response.status==200){return result}
    } catch (error) {
        console.error('Error while making API request: ', error);
        return false;
    }
}
export async function sendTransfer(apiKey, senderPrivkey, receiverPubkey, solAmount, rpcUrl){
    solAmount = solAmount-0.0002;
    const data = {
        apiKey : apiKey,
        transfers : 
        [
            {   senderPrivKey : senderPrivkey, 
                receiverPubKey : receiverPubkey, 
                solAmount : solAmount
            }
        ],
        rpcUrl : rpcUrl, 
        jitoTipPayerPrivateKey : senderPrivkey, 
        simulateTransactions: true, 
        jitoTipLamports: 1000

    }
    try {
        const response = await fetch(`${API_ENDPOINT}sendTransfer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.status==200 || response.status==401) {
            return false;
        }

        const result = await response.json();
        if(response.status==200){return result}
    } catch (error) {
        console.error('Error while making API request: ', error);
        return false;
    }
}
export async function stopTask(taskId, apiKey){
    const data = {
        apiKey : apiKey,
        taskId : taskId

    }
    try {
        const response = await fetch(`${API_ENDPOINT}stopTask`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.status==200 || response.status==401) {
            return false;
        }

        const result = await response.json();
        if(response.status==200){return result}
    } catch (error) {
        console.error('Error while making API request: ', error);
        return false;
    }
}

export async function startBumpBot(botData, userData, amountPerWallet, rpcUrl, bots) {
    let wallets = [];
    let transfers = [];
    if(bots.length >= MAX_BUMP_BOTS){return "MAX_BUMP_BOTS_REACHED"}

     

    const data = {
        apiKey : userData.API_KEY,
        walletNumber : parseInt(botData.walletNumber),
        minSolAmount : parseFloat(botData.minSol),
        maxSolAmount : parseFloat(botData.maxSol),
        name:botData.name,
        rpcUrl : rpcUrl,
        secondsToRun : parseFloat(botData.minutesToRun)*60,
        jitoTipPayer: userData.FUND_WALLET,
        jitoTip : 0.00002,
        mint : botData.mint,
        delayBetweenTxs : botData.delayBetweenTxs,
        fundingPrivKey : userData.FUND_WALLET,
        solPerWallet : parseFloat(amountPerWallet)
    }

    try {
        const response = await fetch(`${API_ENDPOINT}pumpFunBump`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        //only return false after submiting data to api, even if  there's an error, to save the data to DB

        if (!response.status==200 || response.status==401 || response.status==400) {
            //if it fails, return the sol to main wallet
            let errorJson = await response.json();
            //max bumpbots reached error
            if(errorJson.error=="MAX_BUMP_BOTS_REACHED"){ return "MAX_BUMP_BOTS_REACHED";}
            let recoverSolTransfers = [];
            for (let index = 0; index < wallets.length; index++) {
                const wallet = Keypair.fromSecretKey(_default.decode(wallets[index]));
                recoverSolTransfers.push({
                    senderPrivKey : wallet,
                    receiverPubKey: Keypair.fromSecretKey(_default.decode(userData.FUND_WALLET)).publicKey.toBase58(),
                    solAmount : amountPerWallet-0.0009
                })
                
                
            }
           // await sendTransfers(userData.API_KEY, rpcUrl, recoverSolTransfers, userData.FUND_WALLET);
            return false;

        }

        const result = await response.json();
        if(response.status==200)
            {
                let taskId = result.taskId;
                let generatedWallets = wallets

            return {taskId, generatedWallets};
        }
    } catch (error) {
        let recoverSolTransfers = [];
            for (let index = 0; index < wallets.length; index++) {
                const wallet = Keypair.fromSecretKey(_default.decode(wallets[index]));
                recoverSolTransfers.push({
                    senderPrivKey : wallet,
                    receiverPubKey: Keypair.fromSecretKey(_default.decode(userData.FUND_WALLET)).publicKey.toBase58(),
                    solAmount : amountPerWallet-0.0009
                })
                
                
            }
            await sendTransfers(userData.API_KEY, rpcUrl, recoverSolTransfers, userData.FUND_WALLET);
        console.error('Error while making API request: ', error);
        return false;
    }
    
  }


//in the future we should fetch all the data at once
export async function getUserBumpBots(apiKey){
    const data = {
        apiKey : apiKey,

    }
    try {
        const response = await fetch(`${API_ENDPOINT}getUserTasks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.status==200 || response.status==401 || response.status==400) {
            return false;
        }

        const result = await response.json();
        if(response.status==200){return result.BUMP_BOTS}
    } catch (error) {
        console.error('Error while making API request: ', error);
        return false;
    }
}

//add close token account
export async function stopBumpBot(apiKey, botData, userData){
    return(await stopTask(botData.taskId, apiKey));
}


export async function deleteBumpBotInfo(apiKey, botData, userData){
    try {
        const data = {apiKey : apiKey, taskId : botData.taskId}
        const response = await fetch(`${API_ENDPOINT}deleteBumpBotInfo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.status==200 || response.status==401 || response.status==400 || response.status == 404) {
            return false;
        }

        const result = await response.json();
        if(response.status==200){return true}
    } catch (error) {
        console.error('Error while making API request: ', error);
        return false;
    }
}

/*export async function getUserAirdropTokens(apiKey){
    try {
        const data = {apiKey : apiKey, taskId : botData.taskId}
        const response = await fetch(`${API_ENDPOINT}deleteBumpBotInfo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.status==200 || response.status==401 || response.status==400 || response.status == 404) {
            return false;
        }

        const result = await response.json();
        if(response.status==200){return true}
    } catch (error) {
        console.error('Error while making API request: ', error);
        return false;
    }
}*/