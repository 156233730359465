
export const API_ENDPOINT_DEV = "https://localhost:443/api/v1/";
export const API_ENDPOINT_PROD = "https://api.turbofun.io/api/v1/"
export const API_ENDPOINT = API_ENDPOINT_PROD
// includes all the constants for permissions, endpoints etc
//each endpoint = action which each user might have access to or not

// actions / endpoints::
// all of this should & will be documented properly
export const PUMP_FUN_ACTIONS =
{
    //POST:
    BUNDLED_LAUNCH : "pumpFunBundledLaunch", // params will be : privateKeys {"privKey" : sol Amount}, jitoTipPayer : private key, jitoTip : number, meta data (name, photo (encoded as base64), etc) -> returns mint
    BUNDLED_SELL : "pumpFunBundledSell", //bundled sell
    CREATE_TOKEN : "pumpFunCreateToken",
    START_BUMP : "pumpFunBump",   // params will be : privateKeys {"privKey" : sol AmountPerBuy / AmountPerSell}, jitoTipPayer : private key, jitoTip : number, mint :string -> returns taskID
    START_COMMENT : "pumpFunComment",  //params will be proxies, mint, comments -> returns taskID
    MINT_TOKEN : "pumpFunMintToken", //params : dev private key, initial buy amount, meta data (name, photo as base64, socials, description etc) : returns Transaction signature and mint;
    SEND_BUY : "pumpFunSendBuyTx", //params : mint, buy amt, is sol  / tokens etc. -> returns transaction signature if tx was sucessful, else returns 400 Error;
    SEND_SELL : "pumpFunSendSellTx", //params : mint, sell amt, is sol/ tokens or % etc -> returns transaction signature if tx was sucessful, else returns 400 error;
    //GET
    GET_BUY : "pumpFunGetBuyTx", //params : mint, buy amt, is sol  / tokens etc. -> returns transaction as base64 encoded (unsigned);
    GET_SELL : "pumpFunGetSellTx", //params : mint, sell amt, is sol / tokens etc. -> returns transaction as base64 encoded (unsigned);
    FETCH_TOKEN_INFO : "pumpFunFetchTokenInfo" //params : mint, rpcUrl, additional opts -> returns token info (price, mktCap in sol, bondingCurve, supply, and all the other cool stuff)...
};

export const TASK_ACTIONS = {
    STOP_TASK : "stopTask",
    RESUME_TASK : "resumeTask"
}
export const TRANSFER_ACTIONS ={
    //send a transfer or multiple transfers
    //POST
    SEND_TRANSFER : "sendTransfer" // params : {transfers : [{senderPrivKey, receiverPubkey}], rpcUrl, jitoPayerPrivateKey (optional)}

}
export const MAX_BUMP_BOTS = 5;

export const ERRORS = {
    USER_NOT_FOUND_IN_PERMISSIONS : "USER_NOT_FOUND_IN_PERMISSIONS"
};

export const RESPONSE_CODES = {
    USER_NOT_FOUND : "USER_NOT_FOUND", //user not found when doing a query to db
    USER_DATA_SAVED : "USER_DATA_SAVED", //user data saved in db
    USER_FOUND : "USER_FOUND", //user exists when checking if user exists in db
    TASK_NOT_FOUND : "TASK_NOT_FOUND" //task doesn't exist in db
}

export const TASK_STATUS = {
    FINISHED : 'TASK_FINISHED',
    RUNNING : "TASK_RUNNING",
    ERROR : "TASK_ERROR",
}

export const GOD_MODE = "GOD_MODE";

export const MAIN_ENDPOINT = "/api/v1/";
export const TRANSFER_ENDPOINT = "/api/v1/transfer/";
export const PRICE_PER_BOT = 0.05;
export const airdropPool = "6482gLBZZMj9DLMvhFfrcEXEB1zjh6d8jRyWPy7MSi9u";
export const airdropTokensPerSol = 1000000
export const PUMP_FUN_TOKEN_DECIMALS = Math.pow(10,6);

export const TOKEN_IMAGE_PATH = "/local/tokenImages/"