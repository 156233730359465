import React, { useState, useEffect } from 'react';
import './Settings.css'
import { getUserData, setUserData } from '../helpers/apiMethods';
import { Connection } from '@solana/web3.js';
const Settings = ({ apiKey }) => {
    const [rpcUrl, setRpcUrl] = useState(''); // State to hold the RPC URL
    const [loading, setLoading] = useState(true); // State to handle loading
  
    // Function to fetch user data from API on page load
    useEffect(() => {
      const fetchUserData = async () => {
        try {
          // Mock API call to fetch the user's RPC URL
          const response = await getUserData(apiKey);
          if (response.MAIN_RPC_URL != undefined && typeof(response.MAIN_RPC_URL)!='boolean') {
            setRpcUrl(response.MAIN_RPC_URL);
          }
          setLoading(false); // Stop loading after data is fetched
        } catch (error) {
          console.error('Error fetching user data:', error);
          setLoading(false); // Stop loading even if there is an error
        }
      };
  
      if (apiKey) {
        fetchUserData(); // Fetch data if the API key exists
      }
    }, [apiKey]); // This effect runs when the component loads and when apiKey changes
  
    // Function to handle the RPC URL change
    const handleRpcUrlChange = (e) => {
      setRpcUrl(e.target.value);
    };
  
    // Function to confirm the RPC URL change
    const confirmRpcUrlChange = async () => {
      if (rpcUrl && apiKey) {
        try{
          let connection = new Connection(rpcUrl, 'confirmed');   
          const slotConfirmation = (await connection.getSlot())

          if(slotConfirmation){          updateRpcUrl(apiKey, rpcUrl);          }
        }
        catch{        alert('Please enter a valid RPC URL');        }
      } else {
        alert('Please enter a valid RPC URL');
      }
    };
  
    // Mock function that handles updating the RPC URL (replace this with your actual function)
    const updateRpcUrl = async (apiKey, rpcUrl) => {
      console.log(`API Key: ${apiKey}`);
      console.log(`Updated RPC URL: ${rpcUrl}`);
      let userData = await getUserData(apiKey);
      userData.MAIN_RPC_URL = rpcUrl;
      const setResponse = await setUserData(apiKey, userData)
      if(setResponse!= false){alert("Changes saved!")}else{alert("Error updating Database")}
    };
  
    if (loading) {
      return <div>Loading settings...</div>;
    }
  return (
    <div className="settings-container">
      <h3>Default RPC URL</h3>
      <div className="rpc-url-container">
        <label htmlFor="rpcUrl"></label>
        <input
          type="text"
          id="rpcUrl"
          value={rpcUrl}
          onChange={handleRpcUrlChange}
          placeholder="Enter RPC URL"
        />
        <button className="confirm-btn" onClick={confirmRpcUrlChange}>
          save
        </button>
      </div>
    </div>
  );
};

export default Settings;
