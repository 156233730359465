import React, { useState } from 'react';
import BumpBots from './BumpBots';  // Import the new BumpBots component
import bumpBotIcon from '../../assets/bumpBotIcon.png';
import './PumpFunTools.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faRocket } from '@fortawesome/free-solid-svg-icons';

const PumpFunTools = ({ userData, mainRpcConnection }) => {
    const [activeTab, setActiveTab] = useState('BumpBots');

    // Handle tab switching
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="tabs-wrapper">
            <div className="tabs">
                <button
                    className={activeTab === 'BumpBots' ? 'active-tab tab-bump-bots' : 'not-active-button tab-bump-bots'}
                    onClick={() => handleTabChange('BumpBots')}
                >
                    <FontAwesomeIcon icon={faRobot} style={{ color: '#8800ff', marginRight: '10px' , height: '20px', marginTop: '-5px'}} />                  
                    <strong>Bump Bots</strong>
                </button>
                <button
                disabled
                    className={activeTab === 'Launches' ? 'active-tab tab-launches' : 'not-active-button tab-launches'}
                    onClick={() => handleTabChange('Launches')}
                >
                    <FontAwesomeIcon icon={faRocket} style={{ color: '#8800ff', marginRight: '10px' , height: '20px'}} />
                    <strong>Launches (coming soon)</strong>

                </button>
            </div>

            <div className="tab-content-container-pump-fun">
                {activeTab === 'BumpBots' && <BumpBots userData={userData} mainRpcConnection={mainRpcConnection} />}
                {activeTab === 'Launches' && (
                    <div className="launches-content">
                        <h3>Launches</h3>
                        <p>Launch upcoming features or view past launches here.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PumpFunTools;
