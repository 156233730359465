import './RaydiumTools.css'
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faRocket, faTrophy, faMedal, faSignal } from '@fortawesome/free-solid-svg-icons';
import RankBots from './RankBots';
const RaydiumTools = ({userData, mainRpcConnection}) => {
  const [activeTab, setActiveTab] = useState('RankBots');

  // Handle tab switching
  const handleTabChange = (tab) => {
      setActiveTab(tab);
  };

  return (
      <div className="tabs-wrapper">
          <div className="tabs">
              <button
                  className={activeTab === 'RankBots' ? 'active-tab tab-rank-bots' : 'not-active-button tab-rank-bots'}
                  onClick={() => handleTabChange('RankBots')}
              >
                  <FontAwesomeIcon icon={faTrophy} style={{ color: '#8800ff', marginRight: '10px' , height: '20px', marginTop: '0px'}} />                  
                  <strong>Rank Bots</strong>
              </button>
              <button
              disabled
                  className={activeTab === 'VolumeBots' ? 'active-tab tab-volume-bots' : 'not-active-button tab-volume-bots'}
                  onClick={() => handleTabChange('VolumeBots')}
              >
                
                  <FontAwesomeIcon icon={faSignal} style={{ color: '#8800ff', marginRight: '10px' , height: '20px', marginTop: '0px'}} />                  
                  <strong>Volume bots (coming soon)</strong>
              </button>
          </div>

          <div className="tab-content-container-raydium">
              {activeTab === 'RankBots' && <RankBots userData={userData} mainRpcConnection={mainRpcConnection}/>}
          </div>
      </div>
  );
};

export default RaydiumTools;
